import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Icon,
	IconHelp,
	IconSettings,
	IconUser,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	// TODO Add Knobs again
	// Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionMenu } from "../../actions-and-controls/action-menu/action-menu";
import { HorizontalNavigation } from "./horizontal-navigation";

export const Meta: DocPageMeta = {
	category: "Navigation", // Which category does the component belong to?,
	title: "Horizontal navigation", // Should the name in the styleguide menu be different from the component name?,
	notepad: "https://hackmd.io/xHKW4tbtTiqWpz2SGtP5aA", //Go to https://hackmd.io/ , create an account, and create a new note. Provide the url to the note here.
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Horizontal navigation"
			subTitle="A persistent, top-level navigation element that provides quick access to essential site-wide utilities and global actions, and reinforces brand identity."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={HorizontalNavigation} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					This layout is the standard for Siteimprove products and integrations.
				</Paragraph>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Logo:</b> Reinforces brand identity and provides a link back to the homepage.
						</>,
						<>
							<b>Actions:</b> Provide quick access to essential system-level actions (e.g. Sign out,
							Notifications, Profile, Settings, Account switching) through the usage of{" "}
							<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>s and{" "}
							<GatsbyLink to="/lab/components/Actions and controls/Action menu">
								ActionMenu
							</GatsbyLink>
							s.
						</>,
						<>
							<b>Skip to content:</b> ( ): The "Skip to content" link allows keyboard users to
							navigate directly to the main content using the "Tab" key.
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Actions</Header.H3>
				<Paragraph>
					The <Code>actions</Code> prop will render either{" "}
					<GatsbyLink to="/lab/components/Actions and controls/Action menu">ActionMenu</GatsbyLink>s
					or <GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>s
					depending on what values you pass to it. To render an{" "}
					<GatsbyLink to="/lab/components/Actions and controls/Action menu">ActionMenu</GatsbyLink>{" "}
					you pass <Code>ActionMenuProps</Code>. To render a{" "}
					<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink> you can
					pass the following props from the <Code>ButtonProps</Code> type: <Code>children</Code>,{" "}
					<Code>onClick</Code>, <Code>href</Code> and <Code>openNew</Code> plus optionally the
					following props from the <Code>TooltipProps</Code> type to render a tooltip for the
					button: <Code>content</Code> and <Code>placement</Code>.
				</Paragraph>
				<Example fn={UsageWithActionButtons} />
				<Header.H2>Properties</Header.H2>
				{/* TODO Add Knobs again */}
				{/* <Knobs component={HorizontalNavigation} initialProps={{ value: "Some value" }} /> */}
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>Principle</Header.H4>
					<Ul
						items={[
							<>
								<b>Recognition over recall</b>: The <Code>HorizontalNavigation</Code> should present
								familiar options, making it easy for users to find what they need without having to
								remember specific locations.
							</>,
							<>
								<b>Affordances</b>: Icons and labels should clearly communicate their function (e.g.
								a gear icon for settings).
							</>,
							<>
								<b>Consistency</b>: The <Code>HorizontalNavigation</Code> should maintain a
								consistent appearance and behavior across all pages and sections.
							</>,
						]}
					/>
					<Header.H4>General</Header.H4>
					<Ul
						items={[
							<>
								Use the <Code>HorizontalNavigation</Code> for essential, high-level actions that
								users will need frequently throughout their experience.
							</>,
							"Prioritize actions that are global in scope (e.g., sign-in, account settings).",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>HorizontalNavigation</Code> is typically used in the top of the page, e.g
						Sitimprove’s platform, CMS plugins, Browser extensions.
					</Paragraph>

					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>HorizontalNavigation</Code> to existing components for visual
								consistency.
							</>,
							<>
								Consider using a "sticky" or fixed position so the bar remains visible as users
								scroll.
							</>,
							<>
								Use clear, recognizable{" "}
								<GatsbyLink to="/lib/components/Visuals/Icon">Icons</GatsbyLink>. Pair with a
								concise <GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip</GatsbyLink> if
								space allows.
							</>,
							<>
								Use{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Button">
									Icon-only buttons
								</GatsbyLink>{" "}
								for global system-level utilities to reduce visual clutter.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"Actions specific to a page or feature.",
							<>
								The navigation structure is complex and requires multiple levels of sub-menus. Use{" "}
								<GatsbyLink to="/lab/components/Navigation/Side navigation">
									Side Navigation{" "}
								</GatsbyLink>
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient color contrast between icons, text, and the background. ",
							"People with visual impairments may not see the icon at all, so the button's label or aria-label must accurately convey its purpose.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>
						<b>Link labels</b>:
					</Paragraph>
					<Ul
						items={[
							<>Keep labels short and actionable (e.g., "Sign out", or "Notifications").</>,
							"Use sentence case consistently.",
							"Avoid abbreviations and technical jargon.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const siteImproveLogo = (
	<svg
		width="1315.4"
		height="323.8"
		viewBox="0 0 1315.4 323.8"
		fill="white"
		focusable="false"
		aria-hidden="true"
	>
		<g>
			<path d="M169.4,137.5l-11.1-3.3c-5.1-1.5-9.1-2.9-12-4.2c-3-1.3-5.1-2.6-6.3-4.1c-1.2-1.4-1.9-3.2-1.9-5.3 c0-2.6,0.8-4.9,2.5-6.8s4-3.4,7-4.4s6.5-1.6,10.5-1.6c4.1,0,8.1,0.5,12.1,1.6c4,1,7.7,2.5,11.1,4.3c3.4,1.8,6.3,4,8.6,6.4 l14.8-21.9c-5.1-4.9-11.7-8.7-20-11.5s-17.3-4.2-27-4.2c-7,0-13.5,1-19.4,3s-11.1,4.7-15.5,8.3c-4.4,3.6-7.8,7.8-10.2,12.6 c-2.4,4.8-3.7,10.1-3.7,15.8c0,8.9,2.9,16.5,8.7,22.8c5.8,6.3,15.2,11.3,28.4,15l11,3.1c7.7,2.1,13,4.2,15.8,6.5s4.2,5,4.2,8.3 c0,4-2,7.1-5.9,9.2c-3.9,2.1-9,3.2-15.3,3.2c-4.4,0-9-0.6-13.7-1.7c-4.7-1.1-9.1-2.7-13.3-4.8c-4.1-2.1-7.5-4.5-10.2-7.4L104.4,199 c6.3,5.4,14.1,9.6,23.2,12.4s18.5,4.3,28.2,4.3c10.4,0,19.4-1.7,27-5.2s13.4-8.3,17.6-14.5c4.1-6.2,6.2-13.3,6.2-21.4 c0-9.3-2.9-16.8-8.8-22.6S182.5,141.4,169.4,137.5z" />
			<path d="M252.7,77.1c-3.5-3-7.7-4.5-12.6-4.5c-4.8,0-9,1.5-12.6,4.5s-5.3,7-5.3,12.1s1.8,9.2,5.3,12.2 c3.6,3,7.7,4.5,12.6,4.5c4.9,0,9.1-1.5,12.6-4.5c3.5-3,5.3-7.1,5.3-12.2S256.3,80.1,252.7,77.1z" />
			<rect x="225.2" y="119.7" width="29.9" height="93.1" />
			<path d="M335.1,188.4c-1.9,0.5-3.7,0.7-5.6,0.7c-3.5,0-6.3-1-8.5-3s-3.3-5.5-3.3-10.5v-32.1h21.8v-23.7h-21.8V93.1 h-29.9v26.7h-18.1v23.7h18.1v37.3c0,11.6,3,20.3,8.9,26.1s14,8.7,24.4,8.7c4.5,0,8.8-0.6,12.8-1.7s7.5-2.5,10.3-4.1l-3.8-23.5 C338.7,187.3,337,188,335.1,188.4z" />
			<path d="M443,133.4c-4.2-5.1-9.4-9.2-15.8-12.1s-13.7-4.3-21.8-4.3c-7.4,0-14.2,1.2-20.4,3.6 c-6.2,2.4-11.5,5.8-16.1,10.2c-4.5,4.4-8.1,9.6-10.6,15.6s-3.8,12.6-3.8,19.8c0,9.9,2.2,18.6,6.5,26s10.3,13.1,18.1,17.3 c7.7,4.1,16.7,6.2,26.9,6.2c4.6,0,9.5-0.5,14.6-1.5s9.9-2.4,14.4-4.1c4.5-1.7,8.2-3.7,11.1-5.9l-9.4-20.3c-4.5,3-9.2,5.2-14.2,6.6 c-4.9,1.4-10.1,2.1-15.6,2.1c-4.9,0-9.3-0.8-13-2.3c-3.7-1.6-6.6-3.7-8.8-6.4c-2.1-2.7-3.2-5.9-3.3-9.6h69.9 c1.5-8.2,1.4-15.7-0.2-22.7C450,144.6,447.1,138.5,443,133.4z M383.7,154c0.5-4.7,2.6-8.4,6.4-11.2s8.6-4.2,14.4-4.2 c5.7,0,10.3,1.4,13.6,4.2c3.3,2.8,5.1,6.5,5.2,11.2H383.7z" />
			<rect x="470.3" y="119.7" width="29.9" height="93.1" />
			<path d="M497.9,77.1c-3.5-3-7.7-4.5-12.6-4.5c-4.8,0-9,1.5-12.6,4.5s-5.3,7-5.3,12.1s1.8,9.2,5.3,12.2 c3.6,3,7.7,4.5,12.6,4.5s9.1-1.5,12.6-4.5s5.3-7.1,5.3-12.2C503.2,84.1,501.4,80.1,497.9,77.1z" />
			<path d="M666.8,122.1c-5.2-3.4-12.3-5.2-21.3-5.2c-6.9,0-13.5,1.4-19.6,4.1s-11,7-14.7,12.9 c-1.8-5.2-5.2-9.3-10.2-12.4s-10.9-4.6-17.7-4.6c-3.5,0-7,0.5-10.5,1.6s-6.7,2.6-9.8,4.7c-3,2.1-5.7,4.7-7.9,7.9v-11.5h-29.9v93.1 h29.9v-53.1c0-2.9,0.7-5.6,2.1-7.9c1.4-2.3,3.4-4.2,6-5.6c2.6-1.4,5.5-2.1,8.9-2.1c5.2,0,9.1,1.6,11.9,4.7 c2.8,3.1,4.2,7.3,4.2,12.6v51.5h29.9v-53.1c0-2.9,0.7-5.6,2.1-7.9c1.4-2.3,3.4-4.2,6-5.6c2.6-1.4,5.5-2.1,8.8-2.1 c5.2,0,9.2,1.6,12,4.7c2.7,3.1,4.1,7.3,4.1,12.6v51.5H681v-60.1c0-6.1-1.1-11.9-3.2-17.3C675.6,130,672,125.6,666.8,122.1z" />
			<path d="M786.7,123.1c-7-4.1-14.9-6.1-23.8-6.1c-7.1,0-13.4,1.2-18.8,3.7c-5.4,2.4-9.8,5.7-13.3,9.8v-10.7H705v131.5 h29.9v-45.7c3.7,3.2,7.8,5.7,12.4,7.5c4.6,1.8,9.6,2.6,15.1,2.6c6.9,0,13.2-1.2,18.9-3.7s10.6-6,14.8-10.5s7.4-9.7,9.7-15.7 s3.4-12.5,3.4-19.4c0-9.9-2-18.5-6-25.9C799.2,132.9,793.7,127.2,786.7,123.1z M777,178.8c-1.9,3.5-4.6,6.3-8,8.2 s-7.3,2.9-11.6,2.9s-8.2-1-11.6-2.9c-3.4-1.9-6-4.6-8-8.2c-1.9-3.5-2.9-7.7-2.9-12.5c0-4.9,1-9.1,2.9-12.6s4.6-6.2,8-8.1 c3.4-1.9,7.2-2.9,11.5-2.9s8.2,1,11.5,2.9s6.1,4.6,8,8.1c2,3.5,3,7.7,3,12.6C779.9,171.1,778.9,175.3,777,178.8z" />
			<path d="M882.8,116.8c-5.4,0-10.4,1.1-15.1,3.3c-4.6,2.2-8.3,5.7-11.1,10.5v-10.9h-29.9v93.1h29.9v-47.4 c0-6.3,1.8-11,5.5-14.3s8.4-4.9,14-4.9c2.3,0,4.6,0.2,6.9,0.7c2.3,0.5,4.5,1,6.6,1.8l3.6-30.8c-1.6-0.3-3.2-0.6-4.8-0.8 C886.7,116.9,884.9,116.8,882.8,116.8z" />
			<path d="M977.2,123.7c-7.8-4.5-16.8-6.7-26.9-6.7c-7.6,0-14.6,1.3-20.9,4c-6.3,2.6-11.8,6.3-16.4,10.9 c-4.6,4.6-8.2,9.9-10.7,15.8c-2.5,5.9-3.8,12.2-3.8,18.7c0,8.9,2.2,17.2,6.6,24.7c4.4,7.5,10.5,13.5,18.3,17.9 c7.8,4.5,16.8,6.7,26.9,6.7c7.6,0,14.6-1.3,20.9-4s11.8-6.3,16.4-10.9c4.6-4.6,8.2-9.8,10.7-15.7s3.7-12.2,3.7-18.7 c0-8.9-2.2-17.2-6.6-24.7C991.1,134.1,985,128.2,977.2,123.7z M969.8,178.8c-1.9,3.5-4.6,6.3-8,8.2s-7.2,2.9-11.5,2.9 s-8.1-1-11.5-2.9s-6.1-4.6-8.1-8.2c-2-3.5-3-7.7-3-12.5c0-4.9,1-9.1,2.9-12.6s4.6-6.2,8-8.1c3.4-1.9,7.3-2.9,11.6-2.9 s8.2,1,11.6,2.9c3.4,1.9,6.1,4.6,8,8.1s2.9,7.7,2.9,12.6C972.7,171.1,971.8,175.3,969.8,178.8z" />
			<polygon points="1058,181.4 1039.8,119.7 1007.7,119.7 1040.6,212.8 1075.4,212.8 1107.1,119.7 1075.7,119.7 " />
			<path d="M1209.8,151.5c-1.6-7-4.5-13-8.6-18.2c-4.2-5.1-9.5-9.2-15.8-12.1c-6.4-2.9-13.7-4.3-21.8-4.3 c-7.4,0-14.2,1.2-20.4,3.6c-6.2,2.4-11.5,5.8-16.1,10.2c-4.5,4.4-8.1,9.6-10.6,15.6s-3.8,12.6-3.8,19.8c0,9.9,2.2,18.6,6.5,26 s10.3,13.1,18.1,17.3c7.7,4.1,16.7,6.2,26.9,6.2c4.6,0,9.5-0.5,14.6-1.5s9.9-2.4,14.4-4.1c4.5-1.7,8.2-3.7,11.1-5.9l-9.4-20.3 c-4.5,3-9.2,5.2-14.2,6.6c-4.9,1.4-10.1,2.1-15.6,2.1c-4.9,0-9.3-0.8-13-2.3c-3.7-1.6-6.6-3.7-8.8-6.4c-2.1-2.7-3.2-5.9-3.3-9.6 h69.9C1211.5,166.1,1211.4,158.5,1209.8,151.5z M1141.9,154c0.5-4.7,2.6-8.4,6.4-11.2s8.6-4.2,14.4-4.2c5.7,0,10.3,1.4,13.6,4.2 c3.3,2.8,5.1,6.5,5.2,11.2H1141.9z" />
		</g>
	</svg>
);

const initialsStyle = {
	backgroundColor: "var(--color--status--neutral--light)",
	width: "var(--space--xx-large)",
	height: "var(--space--xx-large)",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "50%",
	color: "var(--color--blue--dark)",
	fontFamily: "var(--font-family--heading)",
	fontSize: "var(--font-size--x-small)",
	fontWeight: "var(--font-weight--bold)",
	lineHeight: "var(--line-height--large)",
	letterSpacing: "0.0375rem",
};

const BasicUsage = () => (
	<HorizontalNavigation
		appLogo={{
			icon: siteImproveLogo,
			href: "https://my2.siteimprove.com",
			alt: "Go to main page",
			"data-observe-key": "top-nav-company-link",
		}}
		actions={[
			{
				items: [
					{
						text: "Users",
						onClick: () => console.log("clicked"),
						"data-observe-key": "top-nav-users",
					},
					ActionMenu.divider,
					{ text: "Sites", href: "https://siteimprove.com", "data-observe-key": "top-nav-sites" },
					{ text: "Groups", href: "https://siteimprove.com", "data-observe-key": "top-nav-groups" },
					ActionMenu.divider,
					{ text: "Report", href: "https://siteimprove.com", "data-observe-key": "top-nav-report" },
					ActionMenu.divider,
					{
						text: "Subscriptions",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-subscriptions",
					},
					ActionMenu.divider,
					{
						text: "All settings",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-all-settings",
					},
				],
				buttonContent: (
					<Icon>
						<IconSettings />
					</Icon>
				),
				"data-observe-key": "top-nav-settings",
				"aria-label": "Settings",
				tooltip: "Settings",
			},
			{
				items: [
					{
						text: "Help Center",
						onClick: () => console.log("clicked"),
						"data-observe-key": "top-nav-help-center",
					},
					ActionMenu.divider,
					{
						text: "Frontier",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-frontier",
					},
					ActionMenu.divider,
					{
						text: "Explore more modules",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-explore-more-modules",
					},
				],
				buttonContent: (
					<Icon>
						<IconHelp />
					</Icon>
				),
				"data-observe-key": "top-nav-help",
				"aria-label": "Help and training",
				tooltip: "Help and training",
			},
			{
				items: [
					{
						text: "User Name",
						onClick: () => console.log("clicked"),
						icon: (
							<span style={initialsStyle} aria-hidden="true">
								UN
							</span>
						),
						"data-observe-key": "top-nav-user-name",
					},
					ActionMenu.divider,
					{
						text: "Switch account",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-switch-account",
					},
					{
						text: "Sign out",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-sign-out",
					},
				],
				buttonContent: (
					<Icon>
						<IconUser />
					</Icon>
				),
				"data-observe-key": "top-nav-profile",
				"aria-label": "Profile",
				tooltip: "Profile",
			},
		]}
		data-observe-key="top-nav"
		skipToContentId="properties"
	/>
);

const UsageWithActionButtons = () => (
	<HorizontalNavigation
		appLogo={{
			icon: siteImproveLogo,
			href: "https://my2.siteimprove.com",
			alt: "Go to main page",
			"data-observe-key": "top-nav-company-link",
		}}
		actions={[
			{
				items: [
					{
						text: "Users",
						onClick: () => console.log("clicked"),
						"data-observe-key": "top-nav-users",
					},
					ActionMenu.divider,
					{ text: "Sites", href: "https://siteimprove.com", "data-observe-key": "top-nav-sites" },
					{ text: "Groups", href: "https://siteimprove.com", "data-observe-key": "top-nav-groups" },
					ActionMenu.divider,
					{ text: "Report", href: "https://siteimprove.com", "data-observe-key": "top-nav-report" },
					ActionMenu.divider,
					{
						text: "Subscriptions",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-subscriptions",
					},
					ActionMenu.divider,
					{
						text: "All settings",
						href: "https://siteimprove.com",
						"data-observe-key": "top-nav-all-settings",
					},
				],
				buttonContent: (
					<Icon>
						<IconSettings />
					</Icon>
				),
				"data-observe-key": "top-nav-settings",
				"aria-label": "Settings",
				tooltip: "Settings",
			},
			{
				href: "https://siteimprove.com",
				children: (
					<Icon>
						<IconHelp />
					</Icon>
				),
				tooltip: "Help and training",
				"data-observe-key": "top-nav-help",
				"aria-label": "Help and training",
			},
			{
				onClick: () => console.log("clicked"),
				children: (
					<Icon>
						<IconUser />
					</Icon>
				),
				"data-observe-key": "top-nav-profile",
				"aria-label": "Profile",
			},
		]}
		data-observe-key="top-nav"
		skipToContentId="properties"
	/>
);
